import React from 'react'

import {
  AlertCircleIcon,
  Button,
  Buttons,
  CancelIcon,
  CheckCircleIcon,
  Help,
  Inline,
  List,
  ListItem,
  Message,
  Paragraph,
  Spacer,
  Strong,
  TrashIcon,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Toimintopainikkeet">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          <Strong>Painikkeet voivat olla tyypiltään</Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Strong>Toimintopainikkeita</Strong>: semanttisesti{' '}
              <Code>button</Code>, joka suorittaa jonkin toiminnon.
            </ListItem>
            <ListItem>
              <Strong>Linkkipainikkeita</Strong>: semanttisesti{' '}
              <Code>link</Code>, joka on{' '}
              <Code>
                <Link page="Button" />
              </Code>
              -komponentilla tyyylitelty näyttämään painikkeelta.
              <List
                noMargin
                variant="unordered"
              >
                <ListItem>
                  Linkkipainikkeita käytetään ensisijaisesti ohjaamaan käyttäjä
                  suorittamaan painikkeessa nimetyn toiminnon toisessa
                  näkymässä.
                </ListItem>
                <ListItem>
                  <strong>HUOM!</strong> Työmarkkinatorin avoimilla sivuilla,
                  linkkipainikkeita voidaan käyttää myös korostamaan linkkejä
                  sisältösivuille, joilla ei ole toimintoja.
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>
            Yleisimmin toistuvien toimintopainikkeiden ominaisuudet ja
            toiminnallisuudet ovat vakioituja
          </Strong>{' '}
          (Kts.{' '}
          <a href="#vakioidut-toimintopainikkeet">
            Vakioidut toimintopainikkeet
          </a>
          ).
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Strong>Jos toiminto ei ole vakioitu</Strong>, toimintopainikkeen
              ulkoasu ja toiminnallisuus määritellään toiminnon tärkeyden ja
              ominaisuuksien perusteella geneerisisten painiketyypien mukaisesti
              (Kts.{' '}
              <a href="#geneeriset-painiketyypit">Geneeriset painiketyypit</a>).
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>
            Toimintoa suunnitellessa muista määritellä myös siihen liittyvät
            toiminnallisuudet:
          </Strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>Onko painike toiminto vai linkki?</ListItem>
            <ListItem>
              Varmistetaanko toiminnon suorittaminen varmistusdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              )?
            </ListItem>
            <ListItem>
              Validoidaanko tiedot toiminnon suorittamisen yhteydessä ja
              annetaanko käyttäjäälle palaute virheistä (esim.{' '}
              <Code>
                <Link page="Alert" />
              </Code>
              )?
            </ListItem>
            <ListItem>
              Ilmaistaanko käynnissä olevasta toiminnasta esim. asettamalla
              painike <Code>Loading</Code>-tilaan tai näyttämällä ladattavan
              sisältoalueen kohdalla{' '}
              <Code>
                <Link page="LoadingOverlay" />
              </Code>
              ?
            </ListItem>
            <ListItem>
              Annetaanko käyttäjälle palaute onnistuneesta/epäonnistuneesta
              toiminnosta, (esim.{' '}
              <Code>
                <Link page="Notifications" />
              </Code>{' '}
              tai{' '}
              <Code>
                <Link page="Alert" />
              </Code>
              )?
            </ListItem>
            <ListItem>
              Ohjataanko käyttäjä johonkin toiseen näkymään toiminnon
              suorittamisen jälkeen?
            </ListItem>
            <ListItem>
              Ohjaako toiminto näppäinfokuksen johonkin tietyyn elementtiin?
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Section>

    <Section title="Saavutettavuus">
      <Paragraph noMargin>
        Toimintopainikkeita ei aseteta disabled-tilaan ilman selkeää ilmoitusta
        syystä, miksi painikkeen käyttö on estetty (esim.{' '}
        <Code>
          <Link page="Help" />
        </Code>
        -komponentilla)
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Ilmoitus estämisen syystä asetetaan välittömästi estetyn painikkeen
          läheistyyteen.
        </ListItem>
        <ListItem>
          Ilmoituksen tulee olla saavutettava myös ruudunlukijalla.
        </ListItem>
      </List>
    </Section>

    <Section title="Rakenne ja toiminnallisuus">
      <List variant="unordered">
        <ListItem>
          Painikkeet toteutetaan{' '}
          <Code>
            <Link page="Button" />
          </Code>
          -komponentilla.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Samaan kokonaisuuteen liittyvät toimintopainikkeet kootaan yhteen{' '}
              <Code>
                <Link page="Buttons" />
              </Code>
              -komponentilla.
            </ListItem>
            <ListItem>
              Linkkipainikeelle asetetaan <Code>React Router Link</Code>{' '}
              -komponentti <Code>as</Code>-proppina.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Toimintopainikkeen teksti
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>on mahdollisimman lyhyt, enintään kolme sanaa</ListItem>
            <ListItem>
              kuvaa suoritettavan toiminnon vaikutuksen mahdollisimman selkeästi
            </ListItem>
            <ListItem>alkaa kehoittavalla verbillä</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Kehyksettömissä <Code>Plain</Code>-painikkeissa käytetään{' '}
          <em>aina</em> ikonia (<Code>iconLeft</Code>) erottamaan se
          tavallisesta tekstilinkistä.
        </ListItem>
      </List>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <Paragraph noMargin>
        <Strong>
          Koko näkymään liittyvät toiminnot sijoitetaan pääsääntöisesti näkymän
          loppuun
        </Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos näkymässä on useita koko näkymään liittyviä toimintoja, toiminnot
          sijoitetaan vierekkäin tärkeysjärjestyksessä, niin että tärkein
          toiminto sijoitetaan toiminnoista viimeiseksi.
        </ListItem>
        <ListItem>
          Mobiilinäkymässä (<Code>breakpoint</Code> alle <Code>md</Code>)
          painikkeet asetellaan allekkain, niin että tärkein toiminto on
          ensimmäisenä.
        </ListItem>
        <ListItem>
          Tärkein yksittäinen, koko näkymää koskeva toiminto voidaan nostaa myös
          sivun otsikon yhteyteen
        </ListItem>
        <ListItem>
          Lomakeen koko näkymää koskevista toiminnoista tarkemmin{' '}
          <Link page="Lomakkeen toiminnot">Lomakkeen toiminnot</Link>
          -patternissa
        </ListItem>
      </List>
      <Paragraph>
        <Strong>
          Pienempään osakokonaisuuteen tai tiettyyn näkymän osaan kohdistetut
          toiminnot
        </Strong>{' '}
        asetellaan mahdollisimman lähelle toiminnon kohdetta, saman ryhmän
        taustan sisälle.
      </Paragraph>
    </Section>
    <Section title="Geneeriset painiketyypit">
      <List variant="unordered">
        <ListItem>
          Kaikki toimintopainikkeet noudattavat geneeristen painiketyyppien
          visuaalisia ominaisuuksia ja toiminnallisuuksia.
        </ListItem>
        <ListItem>
          Jos näkymään tarvitaan toiminto, joille ei ole määritelty vakioitua
          toimintoa, toimintapainikkeen tyyppi määräytyy toiminnon tärkeyden ja
          ominaisuuksien perusteella.
        </ListItem>
      </List>
      {/* Pakollinen toiminto */}
      <Spacer marginTop="layout-md">
        <SectionHeading>Pakollinen toiminto</SectionHeading>
      </Spacer>
      <Paragraph>
        Korostettu painike, jolla osoitetaan pakollisia ja peruuttamattomia
        toimintoja.
      </Paragraph>
      <Playground>
        <Button color="secondary">Toiminnon nimi</Button>
      </Playground>
      <Paragraph noMargin>
        <Strong>Käytetään korostamaan näkymän ensijaista toimintoa</Strong>,
        joka on
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>pakollinen</Strong> esimerkiksi prosessin loppuun saattamisen
          tai muun toiminnon kannalta,
        </ListItem>
        <ListItem>
          <Strong>peruuttamaton</Strong>,
        </ListItem>
        <ListItem>
          <Strong>sulkee näkymän.</Strong>
        </ListItem>
      </List>
      <Paragraph noMargin>
        <Strong>Näkymässä saa olla vain yksi pakollinen toiminto.</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos näkymässä on useita koko näkymän liittyviä toimintoja,{' '}
          <Strong>
            pakollinen toiminto sijoitetaan toiminnoista viimeiseksi.
          </Strong>
        </ListItem>
      </List>
      <Help>
        Työmarkkinatorin avointen sivujen päätason näkymissä tärkeimpiin
        toimintoihin tai näkymiin ohjaavana linkkipainikkeena käytetään
        poikkeuksellisesti <Code>color='secondary'</Code>-varianttia{' '}
        <Code>color='primary'</Code>
        -variantin sijaan.
      </Help>
      {/* Pakollisen toiminnon varmistaminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Pakollisen toiminnon suorittaminen varmistetaan <em>aina</em>
            </Strong>{' '}
            käyttäjältä varmistusdialogililla (
            <Code>
              <Link page="Modal" />
            </Code>
            ).
          </Paragraph>
        </Inline>
      </Message>
      {/* Pakollisen toiminnon onnistuminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Pakollisen toiminnon onnistumisesta ilmoitetaan <em>aina</em>
            </Strong>{' '}
            käyttäjälle, tapauskohtaisesti joko
          </Paragraph>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              väliaikaisella ilmoituksella (
              <Code>
                <Link page="Notifications" /> status='success'
              </Code>
              ),
            </ListItem>
            <ListItem>
              varmistus- tai kiitosdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ) (esim. Tietojen lähetys asioinnissa), tai
            </ListItem>
            <ListItem>
              erillisellä varmistus- tai kiitossivulla (esim. A-TMT
              Työnhakijaksi ilmoittatuminen).
            </ListItem>
          </List>
        </Inline>
      </Message>
      {/* Pakollisen toiminnon epäonnistuminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Pakollisen toiminnon epäonnistumisesta varoitetaan käyttäjää{' '}
              <em>aina</em>
            </Strong>
          </Paragraph>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              näkymään lisättävällä varoituksella (
              <Code>
                <Link page="Alert" />
              </Code>
              ), tai
            </ListItem>
            <ListItem>
              näkymän päälle avautuvalla dialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ).
            </ListItem>
          </List>
        </Inline>
      </Message>
      {/* Tärkeä toiminto */}
      <Spacer marginTop="layout-lg">
        <SectionHeading>Tärkeä toiminto</SectionHeading>
      </Spacer>
      <Paragraph>
        Toimintopainikkeen oletusmuoto, jolla korostetaan käyttäjän kannalta
        oleellisia toimintoja.
      </Paragraph>
      <Playground>
        <Button color="primary">Toiminnon nimi</Button>
      </Playground>
      <Paragraph noMargin>
        <Strong>Käytetään, jos toiminto on</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>Koko näkymän ensisijainen tai suositeltu toiminto, </Strong>{' '}
          joka
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>ei ole pakollinen tai peruuttamaton,</ListItem>
            <ListItem>sulkee näkymän.</ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Käyttäjän kannalta tärkeä osa-toiminto</Strong>, joka
          kohdistuu vain tiettyyn näkymän osaan tai osioon
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              voi avata esimerkiksi dialogin (
              <Code>
                <Link page="Modal" />
              </Code>
              ),
            </ListItem>
            <ListItem>ei sulje näkymää.</ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Linkkipainike</Strong>, joka ohjaa käyttäjän toiseen näkymään
        </ListItem>
      </List>
      <Help>
        <Strong>HUOM!</Strong> Työmarkkinatorin päätason näkymissä
        linkkipainikkeen tyylinä käytetään poikkeuksellisesti{' '}
        <Code>color="primary"</Code> sijaan:
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Code>color="secondary"</Code> jos linkki ohjaa käyttäjän tärkeisiin
            toimintoihin tai näkymiin.
          </ListItem>
          <ListItem>
            <Code>variant="outline" color="primary"</Code> jos linkki ohjaa
            käyttäjän vähemmän tärkeisiin näkymiin
          </ListItem>
        </List>
      </Help>
      <Paragraph noMargin>
        <Strong>Näkymässä voi olla useita tärkeitä toimintoja.</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          vähemmän tärkeät toiminnot erotetaan tärkeistä huomioarvoltaan
          vähäisemmillä toissijaisilla toimintopainikeilla tai
          aputoimintopainikeilla.
        </ListItem>
      </List>
      {/* Tärkeän toiminnon vamistaminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Tärkeän toiminnon suorittaminen varmistetaan käyttäjältä{' '}
            <em>tapauksesta riippuen</em>
          </Strong>{' '}
          varmistusdialogililla (
          <Code>
            <Link page="Modal" />
          </Code>
          ).
        </Paragraph>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Koko näkymään liittyvä toiminto <em>tarvittaessa</em> (esim. silloin
            kun toiminto sulkee näkymän)
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toimintoa <em>ei varmisteta</em>
          </Paragraph>
        </Inline>
      </Message>
      {/* Tärkeän toiminnon onnistuminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Tärkeän toiminnon onnistumisesta ilmoitetaan käyttäjälle{' '}
            <em>tapauksesta riippuen</em>
          </Strong>{' '}
          väliaikaisella ilmoituksella (
          <Code>
            <Link page="Notifications" /> status='success'
          </Code>
          )
        </Paragraph>
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            Näkymän ensisijainen toiminto, joka sulkee näkymän <em>aina</em>
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Muu tärkeä tai usein käytetty koko näkymään liittyvä toiminto{' '}
            <em>tarvittaessa</em>.
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toimintoa <em>ei varmisteta</em>
          </Paragraph>
        </Inline>
      </Message>
      {/* Tärkeän toiminnon epäonnistuminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Tärkeän toiminnon epäonnistumisesta varoitetaan käyttäjää{' '}
            <em>tapauksesta riippuen</em>
          </Strong>
        </Paragraph>
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            Näkymän ensisijainen toiminto, joka sulkee näkymän <em>aina</em>{' '}
            näkymään lisättävällä varoituksella (
            <Code>
              <Link page="Alert" />
            </Code>
            ) tai näkymän päälle avautuvalla varoitusdialogilla (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Tärkeä tai usein käytetty koko näkymään liittyvä toiminto
            <em>tarvittaessa</em>.
          </Paragraph>
        </Inline>

        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toimintoa <em>ei varmisteta</em>
          </Paragraph>
        </Inline>
      </Message>
      {/* Toissijainen toiminto */}
      <Spacer marginTop="layout-lg">
        <SectionHeading>Toissijainen toiminto</SectionHeading>
      </Spacer>
      <Paragraph>
        Huomioarvoltaan vähäisempi painike, jolla tarjotaan käyttäjän kannalta
        vähemmän tärkeitä toimintoja.
      </Paragraph>
      <Playground>
        <Button
          color="primary"
          variant="outline"
        >
          Toiminnon nimi
        </Button>
      </Playground>
      <Paragraph noMargin>
        <Strong>Käytetään, jos toiminto on</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>
            Koko näkymään liittyvä toiminto, joka on käyttäjän kannalta vähemmän
            tärkeä.
          </Strong>
        </ListItem>
        <ListItem>
          <Strong>
            Käyttäjän kannalta tärkeä osa-toiminto, jonka käyttäjä on jo
            suorittanut
          </Strong>{' '}
          (esim. asiasanadialogin avaava painike, kun käyttäjä on jo valinnut
          dialogista asiasanoja).
        </ListItem>
        <ListItem>
          <Strong>Linkkipainike</Strong>, joka ohjaa käyttäjän vähemmän tärkeään
          näkymään
        </ListItem>
      </List>
      {/* Toissisjaisen toiminnon vamistaminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Toiminnon suorittaminen varmistetaan käyttäjältä{' '}
            <em>tapauksesta riippuen</em>
          </Strong>{' '}
          varmistusdialogililla (
          <Code>
            <Link page="Modal" />
          </Code>
          ).
        </Paragraph>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Koko näkymään liittyvä toiminto <em>tarvittaessa</em> (esim. silloin
            kun toiminto sulkee näkymän)
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toimintoa <em>ei varmisteta</em>
          </Paragraph>
        </Inline>
      </Message>
      {/* Toissisjaisen toiminnon onnistuminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Toiminnon onnistumisesta ilmoitetaan käyttäjälle{' '}
            <em>tapauksesta riippuen</em>
          </Strong>{' '}
          väliaikaisella ilmoituksella (
          <Code>
            <Link page="Notifications" /> status='success'
          </Code>
          )
        </Paragraph>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Koko näkymään liittyvästä toiminnosta <em>tarvittaessa</em> (esim.
            silloin kun toiminto sulkee näkymän).
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>Osa-toiminnosta ei ilmoiteta</Paragraph>
        </Inline>
      </Message>
      {/* Toissisjaisen toiminnon epäonnistuminen */}
      <Message attentionColor="warning">
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Toiminnon epäonnistumisesta varoitetaan käyttäjää tarvittaessa,{' '}
              <em>tapauksesta riippuen</em>
            </Strong>
          </Paragraph>
        </Inline>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Koko näkymään liittyvästä toiminnosta <em>tarvittaessa</em> näkymään
            lisättävällä varoituksella (
            <Code>
              <Link page="Alert" />
            </Code>
            ) tai näkymän päälle avautuvalla varoitusdialogilla (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </ListItem>
          <ListItem>
            Osa-toiminnosta tarvittaessa väliaikaiselle ilmoituksella (
            <Code>
              <Link page="Notifications" /> status="error"
            </Code>
            ).
          </ListItem>
        </List>
      </Message>
      {/* Aputoiminto */}
      <Spacer marginTop="layout-lg">
        <SectionHeading>Aputoiminto</SectionHeading>
      </Spacer>
      <Paragraph>
        Huomioarvoltaan vähäisempi painike, jolla tarjotaan näkymän yksittäisiin
        osiin kohdistuvia tai prosessin etenemisen keskeyttäviä toimintoja.
      </Paragraph>
      <Playground>
        <Button
          color="primary"
          iconLeft={<XIcon />}
          variant="plain"
        >
          Toiminnon nimi
        </Button>
      </Playground>
      <Help>
        Painikkeessa käytetään <em>aina</em> ikonia erottamaan se tekstilinkistä
        (<Code>iconLeft</Code>)
      </Help>
      <Paragraph noMargin>
        <Strong>Käytetään, jos toiminto on</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Strong>
            Koko näkymään liittyvä toiminto, joka keskeyttää prosessin
            etenemisen ja sulkee näkymän
          </Strong>
        </ListItem>
        <ListItem>
          <Strong>
            Koko näkymään liittyvä toiminto, joka ei ole käyttäjän kannalta
            välttämätön
          </Strong>{' '}
          (esim. Tulosta-painike TMT työnhakuprofiilissa).
        </ListItem>
        <ListItem>
          <Strong>Osa-toiminto</Strong>, joka kohdistuu vain rajattuun näkymän
          osaan, osioon tai yksittäiseen tietoon.
        </ListItem>
      </List>
      {/* Aputoiminnon varmistaminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Toiminnon suorittaminen varmistetaan käyttäjältä{' '}
            <em>tapauksesta riippuen</em>
          </Strong>{' '}
          varmistusdialogililla (
          <Code>
            <Link page="Modal" />
          </Code>
          ).
        </Paragraph>
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            Peruttamaton, prosessin etenemisen keskeyttävä toiminto{' '}
            <em>aina</em>
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Prosessin etenemisen keskeyttävä toiminto joka ei ole peruuttamaton{' '}
            <em>tarvittaessa</em>
          </Paragraph>
        </Inline>
        <Inline gapX="xs">
          <CancelIcon
            color="danger"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toimintoa <em>ei varmisteta</em>
          </Paragraph>
        </Inline>
      </Message>
      {/* Aputoiminnon onnistuminen */}
      <Message attentionColor="warning">
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Toiminnon onnistumisesta ilmoitetaan käyttäjälle tarvittaessa
            </Strong>{' '}
            väliaikaisella ilmoituksella (
            <Code>
              <Link page="Notifications" /> status='success'
            </Code>
            ), esim. tekstin kopioiminen leikepöydälle.
          </Paragraph>
        </Inline>
      </Message>
      {/* Aputoiminnon epäonnistuminen */}
      <Message attentionColor="warning">
        <Paragraph>
          <Strong>
            Toiminnon epäonnistumisesta varoitetaan käyttäjää{' '}
            <em>tapauksesta riippuen</em>
          </Strong>
        </Paragraph>
        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Koko näkymään liittyvästä toiminnosta <em>tarvittaessa</em> näkymään
            lisättävällä varoituksella (
            <Code>
              <Link page="Alert" />
            </Code>
            ) tai näkymän päälle avautuvalla varoitusdialogilla (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </Paragraph>
        </Inline>

        <Inline gapX="xs">
          <AlertCircleIcon
            color="dark"
            size="lg"
          />
          <Paragraph noMargin>
            Osa-toiminnosta tarvittaessa väliaikaiselle ilmoituksella (
            <Code>
              <Link page="Notifications" /> status="error"
            </Code>
            ).
          </Paragraph>
        </Inline>
      </Message>
      {/* Poistava toiminto */}
      <Spacer marginTop="layout-lg">
        <SectionHeading>Poistava toiminto</SectionHeading>
      </Spacer>
      <Paragraph>
        Painike, joka varoittaa käyttäjää tietoa poistavasta toiminnosta.
      </Paragraph>
      <Playground>
        <Buttons>
          <Button color="danger">Toiminnon nimi</Button>
          <Button
            color="danger"
            iconLeft={<TrashIcon />}
            variant="plain"
          >
            Toiminnon nimi
          </Button>
        </Buttons>
      </Playground>
      <Help>
        <Code>variant="plain"</Code>-painikkeessa käytetään <em>aina</em> ikonia
        (<Code>iconLeft</Code>) erottamaan se tekstilinkistä
      </Help>
      <Paragraph noMargin>
        <Strong>Käytetään, jos toiminto</Strong>
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          poistaa tietoja tai näkymän osioita peruttamattomasti,
        </ListItem>
        <ListItem>siirtää julkaistuja tietoja arkistoiduksi,</ListItem>
        <ListItem>
          on muu peruuttamaton, käyttäjän kannalta epäsuotuisa toiminto.
        </ListItem>
      </List>
      {/* Poistavan toiminnon varmistaminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph>
            <Strong>
              Poistavan toiminnon suorittaminen varmistetaan <em>aina</em>
            </Strong>{' '}
            käyttäjältä varmistusdialogilla (
            <Code>
              <Link page="Modal" />
            </Code>
            ).
          </Paragraph>
        </Inline>
        <Paragraph noMargin>
          <Strong>Poikkeus:</Strong> hyvin pienen lomakeosion poistoa ei
          tarvitse varmistaa. Katso tarkemmin Lomakeosion lisääminen -pattern
          (tulossa).
        </Paragraph>
      </Message>
      {/* Poistavan toiminnon onnistuminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Poistavan toiminnon onnistumisesta ilmoitetaan <em>aina</em>
            </Strong>{' '}
            käyttäjälle, väliaikaisella ilmoituksella (
            <Code>
              <Link page="Notifications" /> status='success'
            </Code>
            ).
          </Paragraph>
        </Inline>
      </Message>
      {/* Poistavan toiminnon epäonnistuminen */}
      <Message attentionColor="success">
        <Inline gapX="xs">
          <CheckCircleIcon
            color="success"
            size="lg"
          />
          <Paragraph noMargin>
            <Strong>
              Poistavan toiminnon epäonnistumisesta varoitetaan käyttäjää{' '}
              <em>aina</em>
            </Strong>
          </Paragraph>
        </Inline>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            näkymään lisättävällä varoituksella (
            <Code>
              <Link page="Alert" />
            </Code>
            ), tai
          </ListItem>
          <ListItem>
            näkymän päälle avautuvalla dialogilla (
            <Code>
              <Link page="Modal" />
            </Code>
            ).
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Vakioidut toimintopainikkeet">
      <Paragraph>
        Yleisimmin toistuvien toimintapainikkeiden ominaisuudet ja
        toiminnallisuus ovat vakioituja.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Tarkista toiminnon patternista tarkempi kuvaus suositelluista
          käyttötapauksista ja toimintaperiaatteista.
        </ListItem>
      </List>
      <Help>
        Ohessa lista tunnistetuista vakioiduista toimintapainikkeista. Tarkemmat
        patternit julkaistaan myöhemmin vaihe vaiheelta ja linkitetään listaan.
      </Help>
      <Spacer marginTop="layout-sm">
        <SectionHeading>Tietojen tallennustoiminnot (submit)</SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>
          <Link page="Tallenna-toiminto">Tallenna</Link>
        </ListItem>
        <ListItem>
          <Link page="Lähetä-toiminto">Lähetä</Link>
        </ListItem>
        <ListItem>
          <Link page="Julkaise-toiminto">Julkaise</Link>
        </ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>Julkaisun listätoiminnot</SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>
          <Link page="Esikatsele -toiminto">Esikatsele</Link>
        </ListItem>
        <ListItem>
          <Link page="Tallenna luonnoksena -toiminto">
            Tallenna luonnoksena
          </Link>
        </ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>
          Keskeyttävät toiminnot ja näkymästä poistuminen
        </SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>
          <Link page="Jatka myöhemmin -toiminto">Jatka myöhemmin</Link>
        </ListItem>
        <ListItem>
          <Link page="Keskeytä-toiminto">Keskeytä</Link>
        </ListItem>
        <ListItem>Peruuta</ListItem>
        <ListItem>Takaisin (katselunäkymissä)</ListItem>
        <ListItem>Poistu (käsittelynäkymissä)</ListItem>
        <ListItem>Sulje (dialogeissa)</ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>
          Tietojen lisääminen, muokkaaminen ja poistaminen
        </SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>Lisää</ListItem>
        <ListItem>Muokkaa</ListItem>
        <ListItem>Poista (dokumentaatio vain Confluencessa)</ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>Tietojen hallinnointi</SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>Kopioi uudeksi</ListItem>
        <ListItem>Palauta luonnokseksi</ListItem>
        <ListItem>Arkistoi</ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>
          Tietojen siirtäminen palvelun ulkopuolelle
        </SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>Lataa</ListItem>
        <ListItem>Tulosta</ListItem>
        <ListItem>Kopioi leikepöydälle</ListItem>
      </List>
      <Spacer marginTop="layout-sm">
        <SectionHeading>Navigointitoiminnot</SectionHeading>
      </Spacer>
      <List variant="unordered">
        <ListItem>
          Linkkipainike (visuaalisesti painikkeelta näyttävä linkki{' '}
          <Code>
            Button as={'{'}Link{'}'}
          </Code>
          . Katso geneeriset painiketyypit)
        </ListItem>
        <ListItem>
          <Link page="Monisivuisen lomakkeen navigaatio">
            Monisivuisen lomakkeen navigaatio
          </Link>
        </ListItem>
        <ListItem>
          <Link page="Navigointi pois lomakkeelta">
            Navigointi pois lomakkeelta
          </Link>
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
